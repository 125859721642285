@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "DM Sans", sans-serif !important;
    font-feature-settings: "kern" !important;
    -webkit-font-smoothing: antialiased;
    letter-spacing: -0.5px;
  }
}
:root {
  --loader-bg: rgba(0, 0, 0, 1);
  --loader-shadow: rgba(0, 0, 0, 0.2);
  --loader-bg-alt: rgba(0, 0, 0, 0.4);
  --loader-shadow-alt: rgba(0, 0, 0, 2);
}

[data-theme="dark"] {
  --loader-bg: rgba(255, 255, 255, 1);
  --loader-shadow: rgba(255, 255, 255, 0.2);
  --loader-bg-alt: rgba(255, 255, 255, 0.4);
  --loader-shadow-alt: rgba(255, 255, 255, 2);
}
input.defaultCheckbox::before {
  content: url(../src/assets/svg/checked.svg);
  color: white;
  opacity: 0;
  height: 16px;
  width: 16px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0px);
}

input:checked.defaultCheckbox::before {
  opacity: 1;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus,
.react-tel-input .flag-dropdown.open .selected-flag {
  background-color: transparent !important;
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(20deg);
  }
  20% {
    transform: rotate(-10deg);
  }
  30% {
    transform: rotate(20deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
/* Loder css  */

/* .custom-loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #14bfff;
  border-bottom: 16px solid #14bfff;
  width: 100px;
  height: 100px;
  display: inline-block;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
} */

.no-rotate {
  -webkit-animation: none !important;
  animation: none !important;
}

/* @-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} */

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.custom-loader {
  position: relative;
  width: 75px;
  height: 100px;
  background-repeat: no-repeat;
  background-image: linear-gradient(#ddd 50px, transparent 0),
    linear-gradient(#ddd 50px, transparent 0),
    linear-gradient(#ddd 50px, transparent 0),
    linear-gradient(#ddd 50px, transparent 0),
    linear-gradient(#ddd 50px, transparent 0);
  background-size: 8px 100%;
  background-position: 0px 90px, 15px 78px, 30px 66px, 45px 58px, 60px 50px;
  animation: pillerPushUp 4s linear infinite;
}
.custom-loader:after {
  content: "";
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 10px;
  height: 10px;
  background: #1aaee5;
  border-radius: 50%;
  animation: ballStepUp 4s linear infinite;
}

@keyframes pillerPushUp {
  0%,
  40%,
  100% {
    background-position: 0px 90px, 15px 78px, 30px 66px, 45px 58px, 60px 50px;
  }
  50%,
  90% {
    background-position: 0px 50px, 15px 58px, 30px 66px, 45px 78px, 60px 90px;
  }
}

@keyframes ballStepUp {
  0% {
    transform: translate(0, 0);
  }
  5% {
    transform: translate(8px, -14px);
  }
  10% {
    transform: translate(15px, -10px);
  }
  17% {
    transform: translate(23px, -24px);
  }
  20% {
    transform: translate(30px, -20px);
  }
  27% {
    transform: translate(38px, -34px);
  }
  30% {
    transform: translate(45px, -30px);
  }
  37% {
    transform: translate(53px, -44px);
  }
  40% {
    transform: translate(60px, -40px);
  }
  50% {
    transform: translate(60px, 0);
  }
  57% {
    transform: translate(53px, -14px);
  }
  60% {
    transform: translate(45px, -10px);
  }
  67% {
    transform: translate(37px, -24px);
  }
  70% {
    transform: translate(30px, -20px);
  }
  77% {
    transform: translate(22px, -34px);
  }
  80% {
    transform: translate(15px, -30px);
  }
  87% {
    transform: translate(7px, -44px);
  }
  90% {
    transform: translate(0, -40px);
  }
  100% {
    transform: translate(0, 0);
  }
}

/* @keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} */

/*  sidebaar css */
::-webkit-scrollbar {
  width: 5px;
  cursor: pointer;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  cursor: pointer;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bbbbbb;
  border-radius: 5px;
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bbbbbb;
  border-radius: 5px;
  cursor: pointer;
}
/* ::-webkit-scrollbar {
  width: 5px;
} */
/* ::-webkit-scrollbar-track {
  background: transparent;
} */
/* ::-webkit-scrollbar-thumb {
  background: #1aaee5;
  border-radius: 5px;
} */

.train-quill > div {
  border-radius: 10px;
}
.train-quill > div:focus {
  border-color: #1aaee5;
}
/* .propmt-quill .ql-editor {
  min-height: 160px;
} */
.react-tel-input .country-list .country:hover,
.react-tel-input .country-list .country.highlight {
  background-color: #14bfff !important;
}

.speech-quill .ql-editor {
  min-height: 100px;
}
.custom-toast {
  z-index: 9999;
}
@media screen and (max-width: 600px) {
  .responsive-table,
  .responsive-table-info {
    border: 0;
  }

  .responsive-table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .responsive-table-info thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .responsive-table tr,
  .responsive-table-info tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  .responsive-table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }
  .responsive-table-info td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: left;
  }
  .responsive-table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  .responsive-table-info td::before {
    content: attr(data-label);
    font-weight: bold;
    text-transform: uppercase;
  }
  .responsive-table td:last-child,
  .responsive-table-info td:last-child {
    border-bottom: 0;
  }
}
.send-message-btn {
  position: absolute;
  right: 16px;
  bottom: 20px;
  padding: 0;
  background: transparent;
}
.textarea-resize {
  resize: none;
}
/* .typing-loader {
  margin: 10px;
  width: 6px;
  height: 6px;
  -webkit-animation: line 1s linear infinite alternate;
  -moz-animation: line 1s linear infinite alternate;
  animation: line 1s linear infinite alternate;
} */
.ql-container {
  font-size: 16px !important;
}
/* @-webkit-keyframes line {
  0% {
    background-color: rgba(0, 0, 0, 1);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2),
      24px 0px 0px 0px rgba(0, 0, 0, 0.2);
  }
  25% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 2),
      24px 0px 0px 0px rgba(0, 0, 0, 0.2);
  }
  75% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2),
      24px 0px 0px 0px rgba(0, 0, 0, 2);
  }
}

@-moz-keyframes line {
  0% {
    background-color: rgba(0, 0, 0, 1);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2),
      24px 0px 0px 0px rgba(0, 0, 0, 0.2);
  }
  25% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 2),
      24px 0px 0px 0px rgba(0, 0, 0, 0.2);
  }
  75% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2),
      24px 0px 0px 0px rgba(0, 0, 0, 2);
  }
}

@keyframes line {
  0% {
    background-color: rgba(0, 0, 0, 1);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2),
      24px 0px 0px 0px rgba(0, 0, 0, 0.2);
  }
  25% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 2),
      24px 0px 0px 0px rgba(0, 0, 0, 0.2);
  }
  75% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2),
      24px 0px 0px 0px rgba(0, 0, 0, 2);
  }
} */
.typing-loader {
  margin: 10px;
  width: 6px;
  height: 6px;
  -webkit-animation: line 1s linear infinite alternate;
  -moz-animation: line 1s linear infinite alternate;
  animation: line 1s linear infinite alternate;
}

@-webkit-keyframes line {
  0% {
    background-color: var(--loader-bg);
    box-shadow: 12px 0px 0px 0px var(--loader-shadow),
      24px 0px 0px 0px var(--loader-shadow);
  }
  25% {
    background-color: var(--loader-bg-alt);
    box-shadow: 12px 0px 0px 0px var(--loader-shadow-alt),
      24px 0px 0px 0px var(--loader-shadow);
  }
  75% {
    background-color: var(--loader-bg-alt);
    box-shadow: 12px 0px 0px 0px var(--loader-shadow),
      24px 0px 0px 0px var(--loader-shadow-alt);
  }
}

@-moz-keyframes line {
  0% {
    background-color: var(--loader-bg);
    box-shadow: 12px 0px 0px 0px var(--loader-shadow),
      24px 0px 0px 0px var(--loader-shadow);
  }
  25% {
    background-color: var(--loader-bg-alt);
    box-shadow: 12px 0px 0px 0px var(--loader-shadow-alt),
      24px 0px 0px 0px var(--loader-shadow);
  }
  75% {
    background-color: var(--loader-bg-alt);
    box-shadow: 12px 0px 0px 0px var(--loader-shadow),
      24px 0px 0px 0px var(--loader-shadow-alt);
  }
}

@keyframes line {
  0% {
    background-color: var(--loader-bg);
    box-shadow: 12px 0px 0px 0px var(--loader-shadow),
      24px 0px 0px 0px var(--loader-shadow);
  }
  25% {
    background-color: var(--loader-bg-alt);
    box-shadow: 12px 0px 0px 0px var(--loader-shadow-alt),
      24px 0px 0px 0px var(--loader-shadow);
  }
  75% {
    background-color: var(--loader-bg-alt);
    box-shadow: 12px 0px 0px 0px var(--loader-shadow),
      24px 0px 0px 0px var(--loader-shadow-alt);
  }
}
.ql-editor.ql-blank::before {
  font-style: normal !important;
  font-size: 14px;
  color: #a7b4c5 !important;
}
.ql-container.ql-snow {
  border: none !important;
}
.my-react-quill .ql-disabled {
  background-color: #f2f4f9;
  cursor: unset;
}
.my-react-quill-dark .ql-disabled {
  background-color: #0b1437;
  cursor: unset;
}
.react-tel-input :disabled {
  /* background-color: #f2f4f9 !important; */
  cursor: not-allowed;
  border-color: #adb5bd;
}

.css-1cjy4zv:focus-visible {
  border-color: transparent !important;
}
.css-1cjy4zv {
  background: #e9ecef !important;
}
.css-1cjy4zv:focus {
  background: white !important;
}
.error-message {
  color: red;
}
.recaptcha-container iframe .rc-anchor-dark.rc-anchor-normal,
.recaptcha-container iframe .rc-anchor-dark.rc-anchor-compact {
  border: 2px solid #525252 !important;
}
@media screen and (min-width: 992px) and (max-width: 1365px) {
  .stepper-style span {
    display: none;
  }
}

.numbered-list {
  list-style-type: disc;
  margin-left: 10px;
  padding-left: 10px;
}

.numbered-list li {
  margin-bottom: 5px;
}
.swal2-container {
  z-index: 9999 !important;
}
.ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
}
.mentions-input {
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  padding: 9px;
  border: 1px solid #ccc;
}

.mentions-input__suggestions {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 14px;
}

.mentions-input__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.dark .mentions-input__suggestions {
  background-color: grey;
}
.mentions-input:focus-visible {
  border: 1px solid #adb5bd;
  outline: none;
}
.mentions-input textarea:focus-visible {
  border: 1px solid #adb5bd;
  outline: none;
}
.mentions__mention {
  background-color: #cee0ff; /* Highlight color */
}
.mentions__mention_dark {
  background-color: #056e95; /* Highlight color */
}
.dialer-right {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1 1 0%;
  overflow: auto;
}
/* Hide the focus ring for the Popover trigger */
.chakra-popover__trigger:focus,
.chakra-popover__trigger:focus-visible {
  outline: none !important; /* Removes the default focus outline */
  box-shadow: none !important;
}

/* Alternatively, you can target Popover content */
.chakra-popover__content:focus,
.chakra-popover__content:focus-visible {
  outline: none !important; /* Removes the default focus outline */
  box-shadow: none !important;
}
.css-sjj62m:focus-visible,
.css-sjj62m[data-focus-visible] {
  outline: none !important;
  box-shadow: none !important;
}
:focus-visible {
  outline: none !important;
  box-shadow: none !important;
}
.slick-prev:before,
.slick-next:before {
  color: #14bfff !important;
  font-size: 30px !important;
}
.chakra-slide {
  border-radius: 10px 0 0 10px !important;
}
.otp-container {
  display: flex;
  align-items: center;
}
.otp-container input {
  width: 2.5rem !important;
  height: 2.5rem;
  margin: 0 0.5rem;
  font-size: 1rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, .3);
}
.spinner-icon {
  animation: spin 1s linear infinite;
  font-size: 2rem;
  color: #007bff;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@layer components {
  /* .my-react-select-container {
  } */
  .my-react-select-container .my-react-select__control {
    @apply bg-white dark:bg-navy-900 rounded border-[1px] border-gray-200 dark:border-[1px] dark:border-white/10 dark:hover:border-gray-700;
  }

  .my-react-select-container .my-react-select__control--is-focused {
    @apply border-blue-500 hover:border-blue-500 dark:border-[1px] dark:border-white/10 dark:hover:border-gray-200 shadow-none;
  }

  .my-react-select-container .my-react-select__menu {
    @apply dark:bg-navy-900 rounded border-[1px] border-gray-200 dark:border-[1px] dark:border-white/10;
  }

  .my-react-select-container .my-react-select__option {
    @apply dark:text-navy-200 hover:bg-navy-200 dark:bg-navy-900 dark:hover:bg-navy-800;
  }
  /* .my-react-select-container .my-react-select__option--is-focused {
    @apply bg-navy-200 dark:bg-navy-800;
  } */

  .my-react-select-container .my-react-select__indicator-separator {
    @apply bg-gray-400;
  }

  .my-react-select-container .my-react-select__input-container,
  .my-react-select-container .my-react-select__placeholder,
  .my-react-select-container .my-react-select__single-value {
    @apply text-gray-600 dark:text-white;
  }

    /* Multi-value container and labels */
  .my-react-select-container .my-react-select__multi-value {
    @apply dark:bg-navy-600 rounded-md;
  }

  .my-react-select-container .my-react-select__multi-value__label {
    @apply  dark:text-navy-100;
  }

  /* Multi-value remove button and SVG */
  .my-react-select-container .my-react-select__multi-value__remove {
    @apply dark:bg-navy-700 hover:bg-gray-600 dark:hover:bg-navy-800 rounded-full;
  }

  .my-react-select-container .my-react-select__multi-value__remove .css-tj5bde-Svg {
    @apply  dark:text-gray-400;
  }

  /* Clear and dropdown indicator */
  .my-react-select-container .my-react-select__clear-indicator,
  .my-react-select-container .my-react-select__dropdown-indicator {
    @apply dark:text-gray-600;
  }

  .my-react-select-container .my-react-select__clear-indicator:hover,
  .my-react-select-container .my-react-select__dropdown-indicator:hover {
    @apply dark:text-gray-500;
  }

  /* .my-react-select-container .my-react-select__clear-indicator .css-tj5bde-Svg,
  .my-react-select-container .my-react-select__dropdown-indicator .css-tj5bde-Svg {
    @apply fill-current;
  } */
}
.chakra-accordion__item {
  border-width: 2px;
  border-radius: 8px;
}

.custom-btn-assistant {
  height: auto !important;
}
.circle-main-box {
  box-shadow:
    rgba(0, 0, 0, 0.25) 0px 4px 40px 0px,
    rgba(93, 216, 254, 0.533) 0px 0px 80px,
    rgba(11, 198, 231, 0.533) 0px 0px 0px;
  border-radius: 9999px;
  width: 100%;
  height: 100%;
}
.circle-box-overlay {
  overflow: hidden;
  border-radius: 9999px;
  position: relative;
  width: 100%;
  height: 100%;
  /* background: radial-gradient(
    54.28% 54.28% at 50% 49.91%,
    rgba(0, 0, 0, 0.11) 31.5%,
    rgba(128, 255, 204, 0.1) 83.01%,
    rgba(128, 255, 204, 0.26) 98%
  );
  box-shadow: rgba(128, 255, 204, 0.02) -5.604px -5.604px 280.193px 0px inset;
  backdrop-filter: blur(23.5362px); */
  /* background: radial-gradient(54.28% 54.28% at 50% 49.91%, rgb(70 228 241) 31.5%, rgb(16 186 215 / 75%) 83.01%, rgb(17 215 231 / 87%) 98%);
  box-shadow: rgb(24 242 250) -5.604px -5.604px 280.193px 0px inset;
  backdrop-filter: blur(23.5362px); */
  background: radial-gradient(54.28% 54.28% at 50% 49.91%, #14bfff 31.5%, #14bfff 83.01%, #14bfff 98%);
    box-shadow: #14bfff -5.604px -5.604px 280.193px 0px inset;
    backdrop-filter: blur(23.5362px);
  /* animation: idle 2s infinite; */
}
.circle-box-overlay2 {
  overflow: hidden;
  border-radius: 9999px;
  position: relative;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    54.28% 54.28% at 50% 49.91%,
    rgb(71 205 224) 31.5%,
    rgb(13 217 245) 83.01%,
    rgb(15 192 208) 98%
  );
  box-shadow: rgba(128, 255, 204, 0.02) -5.604px -5.604px 280.193px 0px inset;
  backdrop-filter: blur(23.5362px);
}
.speach-box {
  animation: idle 2s infinite;
}
.backdrop-blur-box2 {
  overflow: hidden;
  border-radius: 9999px;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: radial-gradient(
      54.75% 54.75% at 50% 50%,
      rgb(59 114 113 / 22%) 70.24%,
      rgba(93, 254, 254, 0.6) 100%
    ),
    linear-gradient(
      135deg,
      rgb(20 121 137 / 54%) 0%,
      rgba(93, 254, 202, 0) 100%
    ),
    radial-gradient(
      50% 50% at 50% 50%,
      rgb(19 165 172 / 22%) 0%,
      rgb(22 122 161 / 65%) 90.5%
    );
  background-blend-mode: normal, darken, normal;
}
.backdrop-blur-box {
  overflow: hidden;
  border-radius: 9999px;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: radial-gradient(54.75% 54.75% at 50% 50%, #14bfff69 70.24%, #14bfff54 100%), linear-gradient(135deg, #14bfff66 0%, #14bfff6e 100%), radial-gradient(50% 50% at 50% 50%, #14bfffb8 0%, #14bfff6e 90.5%);
  background-blend-mode: normal, darken, normal;
}
@keyframes idle {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
.circle {
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgb(173, 175, 175);
  box-shadow: 4px -40px 60px 5px rgb(26, 170, 206) inset;
}
.elastic-spin {
  animation: elastic-spin 2s infinite ease;
}
@keyframes elastic-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(720deg);
  }
}
.hstack {
  display: flex;
  flex-direction: row;
}
.text-sm {
  line-height: 1rem !important;
  font-size: 0.875rem !important;
}
.text-base {
  font-size: 14px;
}
.mentions-input__control textarea {
  padding: 10px;
}
.cookies-customization-popup {
  background: #fff;
  padding: 25px;
  border-radius: 12px;
  width: 350px;
  text-align: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.cookies-subtitle {
  font-size: 16px;
  font-weight: bold;
}

.cookies-preference-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  font-size: 14px;
}

.cookies-preference-description {
  font-size: 12px;
  color: #555;
  margin: 5px 0;
  text-align: left;
}

.cookies-save-button {
  background: #00a7e6;
  border: none;
  color: #fff;
  padding: 10px 20px;
  border-radius: 15px;
  cursor: pointer;
  margin-top: 10px;
  font-weight: bold;
}

.cookies-expand-details {
  background: none;
  border: none;
  color: #6c4ae3;
  font-size: 14px;
  margin-top: 10px;
  cursor: pointer;
}

.cookies-switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.cookies-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.cookies-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 20px;
  transition: 0.4s;
}

.cookies-slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.cookies-switch input:checked + .cookies-slider {
  background-color: #00a7e6;
}

.cookies-switch input:checked + .cookies-slider:before {
  transform: translateX(14px);
}

.cookies-button-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 100%;
}